import React from 'react';
import styled from 'styled-components';
import { Page, Section, Row, Split, Header } from 'src/components';
import paths from 'src/constants/paths';

const StyledSubheading = styled.h2`
  /** Force h5 font-size and render semantic h2 elements */
  font-size: var(--fs-b2);
`;
const TrustPage = () => {
  return (
    <Page
      path={paths.TRUST}
      dark
      title="Your trust is our business"
      prefooter={<></>}
      color="#000000"
    >
      <Header
        bg="#000000"
        dark
        title="Your trust is our business"
        text="Privacy and security are core to who we are. We work with personal, sensitive
            information. That’s why we go above and beyond to be a good steward
            of the many things you trust us with."
      />

      <Section small>
        <Split small top>
          <div>
            <StyledSubheading className="serif b">Features</StyledSubheading>
            <Row
              padded
              title="Identity Verification"
              text="IDV checks enforced during sensitive processes, such as healthcare.gov applications"
              icon="/glyphs/new/fingerprint_1.svg"
            />
            <Row
              padded
              title="Encryption"
              text="Fully secured by 256-bit encryption and isolated strictly-controlled VPCs"
              icon="/glyphs/new/protect.svg"
            />
            <Row
              padded
              title="Privacy"
              text="No selling or sharing data. Internal access is limited, logged, and audited."
              icon="/glyphs/new/hand_1.svg"
            />
            <Row
              padded
              title="Separation"
              text="Information across parts of Catch are distributed, not comingled "
              icon="/glyphs/new/separate_document_1.svg"
            />

            <br />
            <StyledSubheading className="serif b">Testing</StyledSubheading>
            <Row
              padded
              title="Independent Audits"
              text="Conducted at least annually, including over 400 CIS and NIST controls"
              icon="/glyphs/new/audit_1.svg"
            />
            <Row
              padded
              title="Automated Monitoring"
              text="Robust alert and logging framework for uptime, money, and enrollment"
              icon="/glyphs/new/notification_1.svg"
            />
            <br />
          </div>
          <div>
            <StyledSubheading className="serif b">Certifications, Licenses</StyledSubheading>
            <Row
              logo
              padded
              title="Enhanced Direct Enrollment"
              text="Approved by the U.S. Center for Medicare and Medicaid Services (CMS)"
              icon="/logos/hhs.png"
              link={{
                text: 'CMS.gov',
                url: 'https://www.cms.gov',
              }}
            />
            <Row
              padded
              title="Health Insurance Producer"
              text="Licensed in all states we offer plans in"
              icon="/glyphs/new/lic.svg"
              link={{ text: 'More' }}
            />
          </div>
        </Split>
      </Section>

      <br />
    </Page>
  );
};

export default TrustPage;
